@import "../../../../styles/breakpoints.scss";
.host {
    display: flex;
    flex-direction: column;     
    padding: 0 0 10px 0;   
}

.companyDataWrapper {
    display: flex;
    flex-direction: column; 
    width: 100%;  
}
/* region Logo */
.logoContainer {
}
.logoDragContainer {
    cursor: pointer;
    flex: 1;
    border: solid silver 2px;
    box-sizing: border-box;
    height: 100px;
    width: 100px;    
    border-radius: 100px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    &[app-drag-active="true"] {
        border: solid silver 3px;
    }
}
.logoUploadIcon {
    width: 20px;
    height: 20px;
}
.logoUploadText {
    align-self: center;
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 10px;
    color: lightgray;
}
.logoAcceptedFormatsText {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: var(--app-color-gray-light_nuance2);
}
.logoUploadPictureContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logoUploadPictureText {
    align-self: center;
    font-weight: 500;
    text-align: center;
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-selected);
}
.logoDragInput {
    display: none;
    align-content: center;
}
.logoContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logoUrlImg {
    object-fit: contain;
    height: 100px;
    width: 200px;
    //height: 200px;
    //max-width: 100%;
}
.logoErrorText {
    margin: 0;
    text-align: center;
    color: var(--app-color-error);
    font-family: var(--app-text-main-font);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0.8rem;
    white-space: pre-wrap;
}
/* endregion Logo */

.companySettingsContainer {
    flex: 1;
    // padding: 0px 50px;
    // @media (--app-viewport-medium-max) {
    //     padding: 10px 50px;
    // }
}
/* region Company Name */
.inputField {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    border-radius: 2px;
    border: 1px solid var(--app-color-gray-lighter);
    width: 100%;
    outline: none;
    height: 56px;
    margin-top: 5px;
    margin-left: 0;
    padding: 0 10px 0 10px;
}
.rowsField {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    border-radius: 2px;
    width: 100%;
    outline: none;
    height: 56px;
    margin-top: 5px;
    margin-left: 0;
    padding: 0 10px 0 10px;
}
.inputFieldOverlay {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    border-radius: 2px;
    //border: 1px solid var(--app-color-gray-lighter);
    width: 100%;
    outline: none;
    height: 56px;
    margin-top: 5px;
    margin-left: 0;
    padding: 0 10px 0 10px;
}
/* endregion Company Name */

/* region DateInputContainer*/
.foundingDateInputContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-radius: 2px;
    height: 56px;
    border: 1px solid;
    border-color: #d2d2d2;
    margin-top: 5px;
}
/* endregion DateInputContainer*/

/*  region Select Employees */
.selectText {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
}
.employeesMOL{
    left: 0px;
    top: calc(100% - 12px);
    width: inherit;
}
/*  endregion Select Employees */

/* region Select industry */
.industryMOL {
    padding: 10px;
    left: -5px;
    top: calc(100% - 12px);
    width: 100%;
}
.industryMOLI {
    padding: 5px;
    border-radius: 5px;
}
/* endregion Select industry */

/* region General error */
.errorGeneralText {
    margin: 10px 0 0 0;
    text-align: left;
    color: var(--app-color-error);
    font-family: var(--app-text-main-font);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0.8rem;
}
/* endregion General error */
.labeledContainerHost {
}
.labeledContainerLabel {
    font-family: var(--app-text-main-font);
    font-size: 24px;
    color: #4a4a4a;
}

.labeledFieldHost {
    margin: 0;
    align-self: center; 
}
.labeledFieldLabel {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-light_nuance2);
    font-weight: bold;
}
.labeledFieldError {
    margin: 0;
    text-align: left;
    color: var(--app-color-error);
    font-family: var(--app-text-main-font);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1rem;
    white-space: pre-wrap;
}
.datePickerRCContainer {
    flex: 1;
    margin-top: 5px; 
}
.calendarSVG {
    align-self: center;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    margin-left: 16px;
    color: var(--app-color-gray-unselected);
    fill: var(--app-color-gray-unselected);
}

/* Upload */
.uploadSelectComponent {
    width: 100%;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    border-radius: 2px;
    border: 1px solid var(--app-color-gray-lighter);
}
.multiselectContainer {
    height: auto;
    cursor: pointer;
    
}
.uploadSearchInput {
    margin-left: 10px;
    
}
.uploadSelectMenuOverlayListItem {
    padding: 10px;
    display: flex;
    align-items: center;
    
}