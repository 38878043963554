.modulesMenu {
  display: flex;
  background-color: white;
  background-color: #F6F4EC;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  &::after {
    content: '';
    width: 100%;
    height: 2px;
  }
}

.statusTitle {
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-family: var(--app-text-main-font);
  font-size: 16px;
  margin-bottom: 20px;
  outline: none;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
}

.buttonIcon {
  width: 24px;
  height: 24px;
}

.filterTitleContainser {
  padding-bottom: 20px;
}

.filterTitle {
  font-family: var(--app-text-main-font);
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: var(--app-color-gray-light);
}

.rightContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  align-content: left;

  & .listButton:hover {
    background-color: var(--app-color-gray-lighter);
  }
}

.listContainerFixed {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  align-content: left;

  & .listButton:hover {
    font-weight: lighter;
    color: var(--app-color-blue);
  }
}

.listContainerAll {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
  align-content: left;

  & .listButton:hover {
    font-weight: lighter;
    color: var(--app-color-blue);
  }

  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
}
.listButton {
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: black;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 20px;
  text-transform: uppercase;

  font-size: 16px;
  font-weight: bolder;
  font-family: var(--app-text-main-font);

}

.dashboardButton {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: bolder;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.25em;
    text-decoration-thickness: 0.01em;
    color: white;
  }

  padding-left: 10px;
  font-family: var(--app-text-open-sans);
  font-size: 18px;
  font-weight: bolder;

}