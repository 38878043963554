.host {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-evenly;
    align-content: center;
}

.closeContainer {
    position: absolute;
    top: -19px;
    right: 19px;
}
.closeElement {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}
.closeIcon {
    width: 48px;
    height: 48px;
}
.target {
    flex: 1;
}
.optionSection {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.optionSettings {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark) !important;
}
.title {
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 24px;
    color: var(--app-color-gray-dark);
    width: 100%;
    word-break: break-word;
    text-transform: capitalize;
    font-weight: bold;
}
.subtitle {
    padding-left: 5px;
    margin-bottom: 40px;
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 14px;
    color: var(--app-color-gray-light);
    width: 100%;
    word-break: break-word;
    
}
.elementInputTitle {
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-light);
    margin: 8px auto;
}

.readOnlyTextInput {
    height: 57px;
    width: 100%;
    padding-left: 20px;
    outline: none;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark) !important;
    border: 1px solid var(--app-color-gray-lighter) !important;
    border-radius: 4px;
    .optionSection[app-variant="error"] & {
        border: 1px solid var(--app-color-red-error) !important;
    }
    background-color: var(--app-color-gray-lighter_nuance1);
}
.elementTextArea {
    height: 57px;
    width: 100%;
    min-width: 100%;
    min-height: 57px; 
    padding: 5px;
    outline: none;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark) !important;
    border: 1px solid var(--app-color-gray-lighter) !important;
    border-radius: 4px;
    .optionSection[app-variant="error"] & {
        border: 1px solid var(--app-color-red-error) !important;
    }
}
.elementTextInput {
    height: 57px;
    width: 100%;
    padding-left: 5px;
    outline: none;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark) !important;
    border: 1px solid var(--app-color-gray-lighter) ;
    border-radius: 4px;
    .optionSection[app-variant="error"] & {
        border: 1px solid var(--app-color-red-error) !important;
    }
}
.unfocusableWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
    &[app-variant="error"] {
        border: 1px solid var(--app-color-red-error);
    }
    &[app-container-variant="noBorder"] {
        border: none;
    }
}

/* Number NumberOptions */
.numberOptionsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
    &[app-variant="error"] {
        border: 1px solid var(--app-color-red-error);
    }
    &[app-container-variant="noBorder"] {
        border: none;
    }
}

.renderBooleansOptions {
    margin: auto 0;
    > div {
        width: 155px;
    }
}

/* Phone Prefix */
.phonePrefixContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
}
.phonePrefixSelectContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
}
.phoneIDropdown {
    //right: -338px;
    width: auto !important;
    min-width: 382px;
    right: auto;
    left: auto;
    top: 18px;
    border-radius: 8px !important;
}
.phoneISearch {
    outline: none;
    input {
        min-width: calc(100% - 10px);
        // width: 100%;
        margin-left: 0 !important;
        margin-right: 10px;
    }
}

.phonePrefixText {
    flex: 1;
}
/* Phone Prefix Input */
.phoneIContainer {
    outline: none;
    flex: 1;
    max-width: 70px;
}
.phoneIInput {
    outline: none;
    display: none;
}
.phoneIButton {
    box-shadow: none;
    & .react-tel-input .country-list {
        display: none;
    }
    & .react-tel-input .selected-flag.open:before{
       content:initial;
       display: none;
    }
    outline: none;
    border: none !important;
    box-shadow: none;
    background-color: transparent !important;
   
}

/* Curency select */
.currencySelectContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
}
.currencySymbol {
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: var(--app-color-gray-dark);
    padding: 0 10px;
}

.currencySelectComponent {
    border: none;
}
.currencySelectMenuOverlayList {
    left: 0px;
    right: 0;
    top: 80%;
}
.currencySelectMenuOverlayListItem {
    padding: 5px;
}
.currencySearchInput {
    max-width: 80%;
}
.currencyClearControlsClassName {
    padding: 0 10px;
    color: var(--app-color-gray-darker);
}

/* Upload */
.uploadSelectComponent {
    width: 100%;
}
.multiselectContainer {
    height: auto;
    cursor: pointer;
}
.uploadSearchInput {
    margin-left: 10px;
}
.maxFileSizeInput {
    &::placeholder {
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        color: var(--app-color-gray-light);
    }
    outline: none;
    padding-left: 10px;
    border: 0px;
    min-width: 350px;
    width: 100%;

    font-family: var(--app-text-main-font), serif;
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}
.uploadFileSizeMeasureUnit {
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: var(--app-color-gray-dark);
    padding: 0 10px;
}
.uploadSelectMenuOverlayList {
    width: 100%;
    top:calc(100% - 15px);
}
.uploadSelectMenuOverlayListItem {
    padding: 10px;
    display: flex;
    align-items: center;
}
/* Date Input */
.dateTimePickerContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
}
.dateTimePickerTrigger {
    flex: 1;
}
.dateAutosizeInput {
    height: 37px;
    margin: auto;
    border: none;
    outline: none;
    width: 100%;
    padding: 1px 2px 1px 10px;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}
/* Date  */
.dateOptionsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
    &[app-variant="error"] {
        border: 1px solid var(--app-color-red-error);
    }
    &[app-container-variant="noBorder"] {
        border: none;
    }
}
/* dropdown select */
.dropdownElementHost {
    display: flex;
    flex-direction: column;
    width: 100%;
    p {
        font-family: var(--app-text-main-font);
        color: var(--app-color-gray-light);
    }
}

.dropdownSymbol {
    padding: 0 10px;
    width: 16px;
    height: 16px;
    color: var(--app-color-gray-light);
}

.dropdownSelectComponent {
    border: none;
}
.dropdownSelectMenuOverlayList {
    right: 0px;
    left: -30px;
    width: calc(100% + 30px);
    top: 76%;
    border-radius: 8px;
    box-shadow: 0px 0px 30px #0000001a;
}
.dropdownSelectMenuOverlayListItem {
    color: var(--app-color-gray-dark);
    padding: 5px 0;
}
.dropdownInput {
    color: var(--app-color-gray-dark);
}
.dropdownClearControlsClassName {
    padding: 0 10px;
    color: var(--app-color-gray-darker);
}
.dropdownListContainer {
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    min-height: 200px;
    width: 100%;
    padding: 12px;
    &[app-variant="error"] {
        border: 1px solid var(--app-color-red-error);
    }
}
.dropDownButtonContainer {
    width: 100%;
    border: 0px dashed var(--app-color-gray-light);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px;
}
.addDropdownOptionButton {
    margin: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    //background-color: var(--app-color-white-dark);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-color-gray-dark);

    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}
.addDropdownOptionIcon {
    margin-right: 4px;
    margin-bottom: -2px;
    color: var(--app-color-gray-dark);
    width: 16px;
    height: 16px;
}
.dropDownNewListItemInputContainer {
    border: 1px solid var(--app-color-gray-lighter);
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    width: 100%;
    height: 50px;
}
.dropDownNewListItemInput {
    &::placeholder {
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        color: var(--app-color-gray-light);
    }
    border: none;
    outline: none;
    margin-left: 10px;
    width: 100%;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}
.dropdownInputButtonSaveNewItem {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 32px;
        height: 32px;
    }
}
.dropdownListItemsContainer {
    max-height: 200px;
    overflow-y: auto;
    margin-right: -11px;
}
.renderEditableListContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--app-color-gray-lighter);
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    &[app-variant="overflow"] {
        margin-right: 4px;
    }
}
.renderEditableListItemInput {
    &::placeholder {
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        color: var(--app-color-gray-light);
    }
    border: none;
    outline: none;
    flex: 1;
    //width: 100%;
    height: 56px;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}
.renderEditableListItemDeleteButton {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-color-gray-dark);
    svg {
        width: 24px;
        height: 24px;
    }
}
/* people select */
.peopleContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.peopleBooleansOptions {
    > div {
        width: 165px;
    }
}
.peoplePositionSelectContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    min-height: 57px;
    height: auto;
}
.peoplePositionsSelect {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.peoplePositionsSelectInput {
}
.peoplePositionsSelectMenuOverlay {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    max-height: 250px;
    width: auto;
    min-width: 100%;
    top: 100%;
}
.peoplePositionsSelectMenuOverlayItem {
    display: flex;

    align-items: center;
    padding: 10px;
    padding-left: 40px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
}
/* ----- */
.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
}

.deleteButton {
    position: absolute;
    left: 0;

    align-self: flex-start;
    outline: none;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;

    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: var(--app-color-gray-dark);
    margin-left: 0;
    padding-left: 0;
}
.errorContainer {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-red-error);
    white-space: pre;
    word-break: break-all;
}

/* Components select*/

.componentsSelectContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
}
.componentsSymbol {
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: var(--app-color-gray-dark);
    padding: 0 10px;
}

.componentsSelectComponent {
    border: none;
    width: 100%;
    padding-left: 10px;
}
.componentsSelectMenuOverlayList {
    left: 0px;
    right: 0;
    top: 80%;
}
.componentsSelectMenuOverlayListItem {
    padding: 5px;
}
.componentsSearchInput {
    max-width: 80%;
}
.ccomponentsClearControlsClassName {
    padding: 0 10px;
    color: var(--app-color-gray-darker);
}